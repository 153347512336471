const links = [
    {
        id: 0,
        page: [
            'home',
            '/'
        ],
    },
    {
        id: 1,
        page: [
            'menu',
            '/menu'
        ],
    },
    
]

export default links